import { Controller } from '@hotwired/stimulus';
import {useDispatch} from "stimulus-use";
import $ from "jquery";

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    static targets=["toggler","myToggler"]
    static values = {
        toggleUrl: String,
    }
    connect() {
        this.dispatch(this, {debug: true});
    }
    async submitForm(event){
        event.preventDefault()


        const $form = $(this.togglerTarget).find('form');
        try{

             $.ajax({
                url: this.toggleUrlValue,
                method: $form.prop('method'),
                data: $form.serialize()
            })
          //  this.dispatch('success')
            if($(this.myTogglerTarget).prop("checked") === true){
                $(this.myTogglerTarget).prop( "checked", false )
            }else {
                $(this.myTogglerTarget).prop("checked", true)
            }
            window.location.reload();



        }catch (e) {
            console.log(e)

        }
    }
}
