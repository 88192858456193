/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

// start the Stimulus application
import './bootstrap';

import * as bootstrap from 'bootstrap'
import $ from "jquery";
import SlimSelect from 'slim-select'

Window.initSlimSelectByClassName = initSlimSelectByClassName
Window.showCardFooters = showCardFooters;
Window.showDiv = showDiv;
Window.showSidebar = showSidebar
window.autotab = autotab

export function initSlimSelectByClassName (className){
    new SlimSelect({
        select: className,
        placeholder: 'Sélectionner',
        searchText: 'Recherche'
    })
}
function autotab(current,to = "")
{

    if (current.getAttribute && current.value.length==current.getAttribute("maxlength"))
    {
        if(to != ""){
            document.getElementById(to).focus()
            document.getElementById(to).setSelectionRange(current.value.length, current.value.length);
        }else{
         var next =  current.nextSibling
            next.focus()
            next.setSelectionRange(next.value.length, next.value.length);
        }


    }
}
function showCardFooters() {
    $(".card-footer").removeClass("d-none");
}
function showDiv(id) {
    $("#"+id).removeClass("d-none");
}
export function showSidebar(){
    $('#sidebarMenu').removeClass("collapse");
}