import { Controller } from '@hotwired/stimulus';
import {Modal} from "bootstrap";
import $ from 'jquery'
import { useDispatch } from 'stimulus-use'

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    static targets=["modal","modalBody"]
    static values = {
        formUrl: String,
    }
    modal = null;

    connect() {
        useDispatch(this, {debug: true});
    }

    async openModal(event){
        console.log("coucou")
        this.modal = new Modal(this.modalTarget);

        this.modalBodyTarget.innerHTML = await $.ajax(this.formUrlValue);
        this.modal.show();

        this.checkManualFocus()



    }
    checkManualFocus(){
        if(document.querySelector("#checkin_indicativeStartDate_hour")) {
            $("#checkin_indicativeStartDate_date_day").attr("disabled", "disabled")
            // $("#checkin_indicativeEndDate_date_day").attr("inputmode", "numeric")
            // $("#checkin_indicativeEndDate_date_day").attr("oninput", "window.autotab(this)")

            $("#checkin_indicativeStartDate_date_month").attr("disabled", "disabled")
            // $("#checkin_indicativeEndDate_date_month").attr("inputmode", "numeric")
            // $("#checkin_indicativeEndDate_date_month").attr("oninput", "window.autotab(this)")

            $("#checkin_indicativeStartDate_date_year").attr("disabled", "disabled")
            $("#checkin_indicativeStartDate_date_year").attr("inputmode", "numeric")
            //   $("#checkin_indicativeEndDate_date_year").attr("oninput", "window.autotab(this)")

            $("#checkin_indicativeStartDate_hour").attr("maxlength", "2")
            $("#checkin_indicativeStartDate_hour").attr("inputmode", "numeric")
            $("#checkin_indicativeStartDate_hour").attr("oninput", "window.autotab(this,'checkin_indicativeStartDate_minute')")

            $("#checkin_indicativeStartDate_minute").attr("maxlength", "2")
            $("#checkin_indicativeStartDate_minute").attr("inputmode", "numeric")
        }
        if(document.querySelector("#checkin_indicativeEndDate_date_day")) {
            $("#checkin_indicativeEndDate_date_day").attr("maxlength", "2")
            $("#checkin_indicativeEndDate_date_day").attr("inputmode", "numeric")
            $("#checkin_indicativeEndDate_date_day").attr("oninput", "window.autotab(this)")

            $("#checkin_indicativeEndDate_date_month").attr("maxlength", "2")
            $("#checkin_indicativeEndDate_date_month").attr("inputmode", "numeric")
            $("#checkin_indicativeEndDate_date_month").attr("oninput", "window.autotab(this)")

            $("#checkin_indicativeEndDate_date_year").attr("maxlength", "4")
            $("#checkin_indicativeEndDate_date_year").attr("inputmode", "numeric")
            //   $("#checkin_indicativeEndDate_date_year").attr("oninput", "window.autotab(this)")

            $("#checkin_indicativeEndDate_time_hour").attr("maxlength", "2")
            $("#checkin_indicativeEndDate_time_hour").attr("inputmode", "numeric")
            $("#checkin_indicativeEndDate_time_hour").attr("oninput", "window.autotab(this,'checkin_indicativeEndDate_time_minute')")

            $("#checkin_indicativeEndDate_time_minute").attr("maxlength", "2")
            $("#checkin_indicativeEndDate_time_minute").attr("inputmode", "numeric")
        }
        if(document.querySelector("#driver_wep_app_birthDate_day")) {
            $("#driver_wep_app_birthDate_day").attr("maxlength", "2")
            $("#driver_wep_app_birthDate_day").attr("inputmode", "numeric")
            $("#driver_wep_app_birthDate_day").attr("oninput", "window.autotab(this)")

            $("#driver_wep_app_birthDate_month").attr("maxlength", "2")
            $("#driver_wep_app_birthDate_month").attr("inputmode", "numeric")
            $("#driver_wep_app_birthDate_month").attr("oninput", "window.autotab(this)")

            $("#driver_wep_app_birthDate_year").attr("maxlength", "4")
            $("#driver_wep_app_birthDate_year").attr("inputmode", "numeric")

        }
        if(document.querySelector("#driver_wep_app_licenceExpiration_day")) {
            $("#driver_wep_app_licenceExpiration_day").attr("maxlength", "2")
            $("#driver_wep_app_licenceExpiration_day").attr("inputmode", "numeric")
            $("#driver_wep_app_licenceExpiration_day").attr("oninput", "window.autotab(this)")

            $("#driver_wep_app_licenceExpiration_month").attr("maxlength", "2")
            $("#driver_wep_app_licenceExpiration_month").attr("inputmode", "numeric")
            $("#driver_wep_app_licenceExpiration_month").attr("oninput", "window.autotab(this)")

            $("#driver_wep_app_licenceExpiration_year").attr("maxlength", "4")
            $("#driver_wep_app_licenceExpiration_year").attr("inputmode", "numeric")

        }

    }
    async submitForm(event){
        event.preventDefault()
        const $form = $(this.modalBodyTarget).find('form');
        try{
            this.modalBodyTarget.innerHTML = await   $.ajax({
                url: this.formUrlValue,
                method: $form.prop('method'),
                data: $form.serialize()
            })
            this.dispatch('success')
            this.modal.hide()

        }catch (e) {
            this.modalBodyTarget.innerHTML = e.responseText
        }



    }
    modalHidden() {
        console.log('it was hidden!');
    }

}
