import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    static targets=['content']
    static values = {
        url: String,
    }
    connect() {//this.element.textContent = 'Hello Stimulus! Edit me in assets/controllers/hello_controller.js';
    }
    async refreshContent(event){
        const response = await fetch(this.urlValue)

            this.contentTarget.innerHTML = await response.text()

        window.addEventListener('DOMContentLoaded', (event) => {

            Fancybox.bind('[data-fancybox="gallery-picture"]', {
                //
            });
            if(document.querySelector("#signature-pad")){
                var signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                    penColor: 'rgb(0, 0, 0)'
                });
                var saveButton = document.getElementById('save');
                var cancelButton = document.getElementById('clear');

                saveButton.addEventListener('click', function (event) {
                    var data = signaturePad.toDataURL('image/png');


                    if (signaturePad.isEmpty() == false) {
                        document.getElementById('signature').value = data

                        document.forms["condition_proceed"].submit();
                    } else {
                        alert('MERCI DE FAIRE SIGNER LE CLIENT')
                    }


// Send data to server instead...
                    //window.open(data);
                });

                // signaturePad.fromDataURL('{{ app.request.get('signature') }}', {
                //     ratio: 1,
                //     width: 500,
                //     height: 400,
                //     xOffset: 0,
                //     yOffset: 0
                // });

                cancelButton.addEventListener('click', function (event) {
                    signaturePad.clear();
                });
            }
        })
        if(document.querySelector("#my-dropzone-video")){
            new window.Dropzone("#my-dropzone-video", {
                // maxFilesize: 1,
                maxFiles: 1,
                paramName: 'reference',
                acceptedFiles: "video/mp4,video/x-m4v,video/*",
            });
        }
        if(document.querySelector("#my-dropzone-pictures")){

            new window.Dropzone("#my-dropzone-pictures", {
                // maxFilesize: 8,
                paramName: 'reference',
                acceptedFiles: "image/*",
            });
        }
        if(document.querySelector("#my-dropzone-damage-video")){
            new window.Dropzone("#my-dropzone-damage-video", {
                // maxFilesize: 1,
                maxFiles: 1,
                paramName: 'reference',
                acceptedFiles: "video/mp4,video/x-m4v,video/*",
            });
        }
        window.addEventListener('', (event) => {

        })
    }
}
