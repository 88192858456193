import { Controller } from '@hotwired/stimulus';
import {Modal} from "bootstrap";
import $ from 'jquery'
import { useDispatch } from 'stimulus-use'

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    static targets=["modal","modalBody"]
    static values = {
        showUrl: String,
    }
    modal = null;

    connect() {
        useDispatch(this, {debug: true});
    }

    async openModal(event){
        console.log("ici")
         this.modal = new Modal(this.modalTarget);
        this.modalBodyTarget.innerHTML = await $.ajax(this.showUrlValue);
        this.modal.show();
    }
    modalHidden() {
        console.log('it was hidden!');
    }

}
